import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/commons";
import Seo from "../components/layout/seo";

// Statically generate index because Gatsby need it
// somewhat duplicate of templates/page.js
const Index = ({ data }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  return (
    <Layout languageMappings={{ default: "/", en: "/", de: "/" }}>
      <Seo
        info={story?.content?.page_information[0]}
        lm={{ default: "/", en: "/", de: "/" }}
        lang="default"
      />
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

export const query = graphql`
  query Index {
    storyblokEntry(id: { eq: "storyblokentry-162524901-default" }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(
      filter: { base: { in: ["chi-siamo.png", "chi-siamo-mob.png"] } }
    ) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Index;
